(function($) {

	function firstAlphabetical(locale, letter1, letter2) {
		if (new Intl.Collator(locale).compare(letter1, letter2) > 0) {
			return letter1;
		}
		return letter2;
	}

	function listBuilder(){
		var $i = 0;
		var sorted = awards;
		var sortIds = $('.awards th');

		$(".tableControl tbody").html("");

		$('.tableControl th.sortable.active').each(function(){
			var sortId = $(this).attr('data-sortid');
			var upDown = ($(this).hasClass('down')) ? "up" : "down";
			
			sortArray = sorted;
			sortArray = sortArray.slice(0).sort(function(a, b) {
				var textA = a[sortId].toUpperCase();
				var textB = b[sortId].toUpperCase();
				if(upDown === "up"){
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				}else{
					return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
				}
			});

			sorted = sortArray;
		});

		$.each(sorted, function(key, value){
			var $string = "";

			$string += "<tr>";
			$.each( sortIds, function(){
				var $sortId = $( this );
				$string += "<td class='"+$sortId.data( 'sortid' )+"'><span class='mobLabel'>"+$sortId.html()+"</span>"+ value[ $sortId.data( 'sortid' ) ] +"</td>";
			} );
			$string += '</tr>';
			$i++;
			$(".tableControl tbody").append($string);
		});
		$(".tableControl").removeClass("faded");
	}

	// Search Function
	function runSearch(reset){
		var newArray = [];
		var sortType = $('.sort .active').attr('data-sort');

		if(selGenre.hasClass('all') && searchString.length === 0 && sortType === "featured"){
			listBuilder(titles);
		}else{
			var toSort = (sortType === "alphabetical") ? true : false;
			var arrayToUse = "";

			if(selGenre.hasClass('all') && searchString.length === 0){
				arrayToUse = "titles";
			}else{
				arrayToUse = "newArray";

				$.each(titles, function(key, value){
					var searchMatch = (value.title.toLowerCase().indexOf(searchString) >= 0);
					var genreMatch = (value.genre.toLowerCase().indexOf(genreString) >= 0);

					if((searchMatch && genreMatch) || (searchMatch && selGenre.hasClass('all')) || (genreMatch && searchString.length === 0)){
						newArray.push(value);
					}
				});
			}
			if(sortType === "alphabetical" && toSort){
				var upDown = ($('.sort .alphabetical').hasClass('down')) ? "down" : "up";
				console.log("Sort "+ upDown);
				sortArray = (arrayToUse === "titles") ? titles : newArray;
				sortArray = sortArray.slice(0).sort(function(a, b) {
					var textA = a.title.toUpperCase();
					var textB = b.title.toUpperCase();
					if(upDown === "up"){
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					}else{
						return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
					}
				});
				listBuilder(sortArray);
			}else{
				listBuilder(newArray);
			}
		}
	}

	if($('.tableControl').length > 0){

		listBuilder();

		var delay = (function(){
			var timer = 0;
			return function(callback, ms){
				clearTimeout (timer);
				timer = setTimeout(callback, ms);
			};
		})();

		$(document).on('click', '.tableControl th.sortable', function(){
			
			var th = $(this);
			var colIndex = th.index() + 1;
			var caseToChange = "up";

			if (th.hasClass('down')) {
				caseToChange = "none";
			} else if (th.hasClass('active')) {
				caseToChange = "down";
			}
			$('.tableControl th.active').removeClass('down');
			$('.tableControl th.active').removeClass('up');
			$('.tableControl th.active').removeClass('active');

			if (caseToChange === "down"){
				th.addClass('active');
				th.addClass('down');
			}else{
				th.addClass('active');
				th.addClass('up');
			}
			$(".tableControl").addClass("faded");
			delay(function(){
				listBuilder();
				$(".tableControl thead tr th.active").each(function(){
					var thIndex = $(this).index() + 1;
					$(".tableControl tr td:nth-child("+ thIndex +")").addClass("active");
				});
			}, 200 );
		});

	}
})(jQuery);
