( function( $, Vimeo ){

    $.fn.avantiPlayer = function(){

        return this.each( function(){

            var $container,
                $player,
                player,
                $thumbnail,
                $playlist,
                $currentItem;

            function thumbnailClick(e) {
                $thumbnail.fadeOut();
                player.play();
            }

            function setThumbnail() {
                $thumbnail.removeClass("ready").css({
                    "background-image": "url( " + $currentItem.data("thumbnail") + " )"
                }).click(thumbnailClick);
            }

            function setPlayerInfo() {
                $container.find(".zonepro-player-title").html($currentItem.data("title"));
                $container.find(".zonepro-player-description").html($currentItem.data("description"));
                $container.find(".zonepro-player-time").html($currentItem.data("time"));
                if( $currentItem.data( "download" ) ){
                    $container.find( ".zonepro-player-download" ).attr( "href", $currentItem.data( "download" ) );
                    $container.find( ".zonepro-player-download" ).show();
                }else{
                    $container.find( ".zonepro-player-download" ).hide();
                }
            }

            function playerReady() {
                $thumbnail.addClass("ready");
            }

            function playerEnded() {
                $thumbnail.fadeIn();
            }

            function initPlayer() {
                setPlayerInfo();
                player = new Vimeo.Player($player, {
                    id: $currentItem.data("id")
                });
                player.ready().then(playerReady);
                player.on("ended", playerEnded);
                player.on("pause", playerEnded);
            }

            function loadVideo() {
                player.loadVideo($currentItem.data("id")).then(playerReady);
            }

            function playlistClick(e) {
                var clickedItem = $(this);
                if (clickedItem.data("id") !== $currentItem.data("id")) {
                    $currentItem = clickedItem;
                    setPlayerInfo();
                    setThumbnail();
                    loadVideo();
                }
                $("html, body").animate({
                    scrollTop: $container.offset().top + "px"
                });
                e.preventDefault();
            }

            function initPlaylist() {
                $playlist.each(function () {
                    $(this).find(".zonepro-player-item-link").click(playlistClick);
                });
            }

            function init( container ){
                $container = $( container );
                $player = $container.find( ".zonepro-player" );
                $thumbnail = $container.find( ".zonepro-thumbnail" );
                $playlist = $container.find( ".zonepro-player-items" );
                $currentItem = $playlist.find( ".zonepro-player-item a" ).first();
                if( $currentItem.length > 0 ){
                    setThumbnail();
                    initPlayer();
                    initPlaylist();
                }
            }

            init( this );

        } );

    };

    $( document ).ready( function(){
        $( ".zonepro-player-container" ).avantiPlayer();
        if( location.hash ){
            window.scrollTo(0, 0);
            $( location.hash ).siblings( ".profile-distribution-toggle" ).filter( '.collapsed' ).trigger( "click" );
            $( "html, body" ).animate( {
                scrollTop: $( location.hash ).offset().top
            } );
        }
    } );


} )( jQuery, Vimeo );