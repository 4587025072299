(function($) {

	String.prototype.noAccents = function( str ){
		if ( String.prototype.normalize ){
			return this.normalize( 'NFD' ).replace(/[\u0300-\u036f]/g, "");
		}else{
			console.log( this );
			return this.replace( /[àäâ]/g, 'a').replace( /[éèêë]/g, 'e').replace( /[ûü]/g, 'u' ).replace( /[ç]/g, 'c' );
		}
	}

	function listBuilder(data){
		var $i = 0;
		$(".listingList .row").html("");
		if( data.length > 0 ){
			$( ".listingList .no-results" ).hide();
			$.each(data, function(key, value){
				var $string = "";
				var image = value.image;
				if($i < 2){
					$string += "<div class='col-md-6'>"+
					"<div class='listItem' data-distribution='"+value.distribution_format+"'>"+
					"<div class='itemBackground js-tilt-half' style='background: url("+ image +")center center no-repeat;background-size: cover;'>";
				}else{
					$string += "<div class='col-md-4'>"+
					"<div class='listItem' data-distribution='"+value.distribution_format+"'>"+
					"<div class='itemBackground js-tilt-full' style='background: url("+ image +")center center no-repeat;background-size: cover;'>";
				}
				$i++;
				$string += "<a href='"+ value.url +"' class='gradientShadow'></a>"+
				"<div class='content'>"+
					"<div class='genre'>"+ value.genre +"</div>"+
					"<a href='"+ value.url +"' class='title'>"+ value.title +"</a>"+
				"</div>"+
				"</div>"+
				"</div>"+
				"</div>";
				$(".listingList .row").append($string);
			});
		}else{
			$( ".listingList .no-results" ).fadeIn( 300 );
		}
		$(".listingList").removeClass("faded");
	}

	// Search Function
	function runSearch(reset){
		var newArray = [];
		var searchString = $('.search .titleSearch').val().toLowerCase().noAccents();
		var selGenre = $('.genre.active');
		var genreString = selGenre.html().toLowerCase();
		var sortType = $('.sort .active').attr('data-sort');
		var distributionSort = $( '.distribution-format.active' ).data( 'format' );

		if(selGenre.hasClass('all') && searchString.length === 0 && sortType === "featured" && !distributionSort){
			console.log(titles);
			listBuilder(titles);
		}else{
			var toSort = (sortType === "alphabetical") ? true : false;
			var arrayToUse = "";
			if(selGenre.hasClass('all') && searchString.length === 0 && !distributionSort){
				arrayToUse = "titles";
			}else{
				arrayToUse = "newArray";

				$.each(titles, function(key, value){
					var searchMatch = (value.title.toLowerCase().noAccents().indexOf(searchString) >= 0);
					var genreMatch = (value.genre.toLowerCase().indexOf(genreString) >= 0);
					var distributionMatch = ( value.distribution_format.indexOf( distributionSort ) >= 0 );
					console.log( value.distribution_format );

					if(  ( distributionMatch ||  !distributionSort ) && ( (searchMatch && genreMatch) || (searchMatch && selGenre.hasClass('all')) || (genreMatch && searchString.length === 0) ) ){
						newArray.push(value);
					}
				});
			}
			if(sortType === "alphabetical" && toSort){
				var upDown = ($('.sort .alphabetical').hasClass('down')) ? "down" : "up";
				console.log("Sort "+ upDown);
				sortArray = (arrayToUse === "titles") ? titles : newArray;
				sortArray = sortArray.slice(0).sort(function(a, b) {
					var textA = a.title.toLowerCase().noAccents();
					var textB = b.title.toLowerCase().noAccents();
					if(upDown === "up"){
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					}else{
						return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
					}
				});
				listBuilder(sortArray);
			}else{
				listBuilder(newArray);
			}
		}
	}

	function scrollTo(){
		var headerHeight = $( "#main-header" ).height();

		$( "html, body" ).animate( {
			scrollTop: $( ".genres" ).offset().top - headerHeight - 1 + "px"
		} );
	}

	if($('.archive .listingList').length > 0){

		// Click on Search icon
		$(document).on('click', '.search .searchbutton', function(){
			$('.search .titleSearch').focus();
		});

		$(document).on('focus', '.search .titleSearch', function(){
			$('.search').addClass('enabled');
		});
		$(document).on('focusout', '.search .titleSearch', function(){
			if($('.search .titleSearch').val().length === 0){
				$('.search').removeClass('enabled');
			}
		});


		listBuilder(titles);

		var delay = (function(){
			var timer = 0;
			return function(callback, ms){
				clearTimeout (timer);
				timer = setTimeout(callback, ms);
			};
		})();

		$(document).on('click', '.search .delete', function(){
			$('.search .titleSearch').blur();
			$('.search').removeClass('enabled');
			$('.search .titleSearch').val('');
			$(".listingList").addClass("faded");
			delay(function(){
				runSearch();
			}, 100 );
		});

		// Click on Sort
		$(document).on('click', '.sort>div', function(){
			var sortType = ($(this).attr("data-sort") === "featured") ? "feat" : "alph";
			if(sortType === "feat"){
				if(!$(this).hasClass('active')){
					$('.sort .active').removeClass('active');
					$(this).addClass('active');
				}
			}else{
				if(!$(this).hasClass('active')){
					$('.sort .active').removeClass('active');
					$(this).addClass('active');
				}else{
					$(this).toggleClass('down');
				}
			}
			$(".listingList").addClass("faded");
			delay(function(){
				runSearch();
			}, 200 );
		});

		$('.search .titleSearch').keyup(function() {
			if($('.search .titleSearch').val().length > 2){
				$(".listingList").addClass("faded");
				delay(function(){
					runSearch();
				}, 400 );
			}else if($('.search .titleSearch').val().length === 0){
				$(".listingList").addClass("faded");
				delay(function(){
					runSearch();
				}, 100 );
			}
		});

		$(document).on("click", ".genres .genre", function(){
			$(".genre.active").removeClass("active");
			$(this).addClass("active");
			$(".listingList").addClass("faded");
			delay(function(){
				runSearch();
				scrollTo();
			}, 200 );
		});

		$(document).on("click", ".listItem .genre", function(){
			$(".genre.active").removeClass("active");
			var genreSelected = $(this).html().toLowerCase();
			console.log(genreSelected);
			$(".genres [data-genre='"+ genreSelected +"']").addClass("active");
			$(".listingList").addClass("faded");
			delay(function(){
				runSearch();
			}, 200 );
		});

		$(document).on( 'click', '.distribution-formats .distribution-format', function(){
			$( '.distribution-format.active' ).removeClass( 'active' );
			$( this ).addClass( 'active' );
			$(".listingList").addClass("faded");
			delay(function(){
				runSearch();
			}, 200 );
		} );

		runSearch();
	}
})(jQuery);
