/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
		scrollTop: 0,
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var footerHeight = $('footer.content-info').height();

        $(window).resize(function(){
          footerHeight = $('footer.content-info').height();
        });

        $(window).scroll(function(){
					var $window = $(this);
					var $header = $("#main-header").first();

					if( $window.scrollTop() > 300 ){
						$header.addClass("sticky");
					}else{
						$header.removeClass("sticky");
					}

					if( $window.scrollTop() < Sage.scrollTop ){
						$header.addClass("up");
					}else{
						$header.removeClass("up");
          }
          
          if ($window.scrollTop() > 300) {
            $('body.single .socialBar').addClass('sticky');
          } else {
            $('body.single .socialBar').removeClass('sticky');
          }

          var distanceFromBottom = Math.floor($(document).height() - $(document).scrollTop() - $(window).height());
          var checkDistance = distanceFromBottom <= footerHeight;
          // console.log($(document).height() + " - " + $(document).scrollTop() + " - " + footerHeight);
          
          if ( checkDistance ){
            $('body.single .socialBar').addClass('atBottom');
          }else{
            $('body.single .socialBar').removeClass('atBottom');
          }

					Sage.scrollTop = $window.scrollTop();
        });

        $(".socialIcons a").click(function(e){
          e.preventDefault();
          window.open( $(this).attr("href"), null, "width=560,height=560" );
        });

        $(".navbar-toggler").click(function(){
          var $this = $(this);
          if( $this.hasClass( "collapsed" ) ){
            $("body").addClass( "primary-navigation-opened" );
          }else {
            $("body").removeClass( "primary-navigation-opened" );
          }
        });

				$(document).ready(function(){
					$('.remoteSelect').each(function(){
						var remote = $("#"+ $(this).attr('data-remote'));
						var content = $(this).find('li.selected .content').html();
						var title = $(this).find('li.selected .title').html();

						remote.find('h3').html(title);
						remote.find('.content').html(content);
					});
				});

				$(document).on('click', '.remoteSelect li', function(){
					var remoteSelect = $(this).parent().parent();
					var remote = $("#"+ remoteSelect.attr('data-remote'));
					var title = $(this).find('.title').html();
					var content = $(this).find('.content').html();

					$('.selected').removeClass('selected');
					$(this).addClass('selected');
					remote.find('h3').html(title);
					remote.find('.content').html(content);
        });

        $( "#newsletter-footer-form" ).submit( function(e){
          e.preventDefault();
          var target = $( this ).data( "target" );
          $( target ).modal( "show" );
        } );

        $( "[href='#infolettres']" ).click( function(e){
          e.preventDefault();
          $( "#modal-newsletter" ).modal( "show" );
        } );

        $( "#modal-newsletter form" ).submit( function( e ){
          e.preventDefault();
          var $form = $( this );
          
          var formData = $form.serialize();
          $.ajax( {
            url: "/wp/wp-admin/admin-ajax.php",
            data: formData,
            method: "POST",
            beforeSend: function(){
              $form.find( "[type=submit]" ).attr( "disabled", "disabled" );
            },
            success: function( resp ){
              // if( resp.code === 200 ){
                $form.html( resp );
              // }
            },
            error: function( code ){
              alert( code );
            }
          } );
        } );

        $( "#modal-newsletter" ).on( "show.bs.modal", function( e ){
          var email = $( "#modal-newsletter-toggle-email" ).val();
          var target =  $( "#modal-newsletter-email" );
          target.val( email );
        } );

        $( "#select-news-year" ).change( function(){
          window.location.href = $( this ).val();
        } );
        
        $( ".gfield textarea" ).removeAttr( "rows" ).keydown( function(){
          el = this;
          setTimeout( function(){
            el.style.cssText = "height: 0;";
            el.style.cssText = "height: " + el.scrollHeight + "px";
          }, 0 );
        } );


        $('.gfield input[type="text"], .gfield select, .gfield textarea').each(function () {

          var field = $(this).closest(".gfield");

          if ( $(this).val().length > 0) {
            field.addClass('moveLabel');
          }
        });

        $('.gfield input[type="text"], .gfield select, .gfield textarea').on('blur', function () {

          var field = $(this).closest(".gfield");

          if (field.hasClass("moveLabel") && $(this).val().length === 0) {
            field.removeClass('moveLabel');
          }
        }).on('focus', function () {
          var field = $(this).closest(".gfield");

          field.addClass('moveLabel');
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
