( function( $, Vimeo ){

    $.fn.avantiGallery = function(){

        return this.each( function(){
            
            var $container,
                $player,
                player,
                playerSet,
                $thumbnail,
                $playlist,
                $currentItem,
                timer,
                direction,
                lastX;

            function playerReady() {
                playerSet = true;
                $thumbnail.addClass("ready");
            }

            function playerEnded() {
                $thumbnail.fadeIn();
            }

            function initPlayer() {
                player = new Vimeo.Player($player, {
                    id: $currentItem.data("video-id")
                });
                player.ready().then(playerReady);
                player.on("ended", playerEnded);
                player.on("pause", playerEnded);
            }

            function thumbnailClick(e) {
                if ($currentItem.data("type") === 'vimeo') {
                    $thumbnail.fadeOut();
                    player.play();
                }
            }

            function setThumbnail() {
                $thumbnail.removeClass("ready").css({
                    "background-image": "url( " + $currentItem.data("src") + " )"
                }).click(thumbnailClick);
            }

            function loadVideo() {
                player.loadVideo($currentItem.data("video-id")).then(playerReady);
            }

            function playlistClick( e ){
                var clickedItem = $( this );
                var headerHeight = $( "#main-header" ).height();
                if( clickedItem.attr( "id" ) !== $currentItem.attr( "id" ) ){
                    $currentItem = clickedItem;
                    setThumbnail();
                    if ($currentItem.data("type") === "vimeo") {
                        loadVideo();
                    }
                }
                if (playerSet) {
                    player.pause();
                }
                $( "html, body" ).animate( {
                    scrollTop: parseInt( $container.offset().top - headerHeight - 1 ) + "px"
                } );
                e.preventDefault();
            }

            function initPlaylist() {
                $playlist.each(function () {
                    $(this).find(".gallery-item-link").click(playlistClick);
                });
            }

            function controlAnimate() {
                if (direction === 'next') {
                    step = "+=10px";
                } else {
                    step = "-=10px";
                }
                $playlist.animate({
                    scrollLeft: step
                }, 10, "linear");
            }

            function controlEnter(e) {
                clearInterval(timer);
                direction = $(this).data("direction");
                timer = setInterval(controlAnimate, 10);
            }

            function controlOut(e) {
                clearInterval(timer);
            }

            function playlistTouchStart(e) {
                lastX = e.originalEvent.touches[0].clientX;
            }

            function playlistTouchMove(e) {
                event = e.originalEvent;
                currentX = event.touches[0].clientX;
                if (currentX > lastX) {
                    distance = currentX - lastX;
                    $playlist.scrollLeft($playlist.scrollLeft() - distance);
                } else if (currentX < lastX) {
                    distance = lastX - currentX;
                    $playlist.scrollLeft($playlist.scrollLeft() + distance);
                }

                lastX = currentX;
            }

            function setControls() {
                var distance = 0;
                if ($playlist[0].offsetWidth < $playlist[0].scrollWidth) {
                    $playlist.parent().addClass("show-controls");
                }

                $container.find(".control").mouseenter(controlEnter);
                $container.find(".control").mouseout(controlOut);
                $playlist.on("touchstart", playlistTouchStart);
                $playlist.on("touchmove", playlistTouchMove);
            }
            
            function init( container ){
                playerSet = false;
                $container = $( container );
                $player = $container.find( ".fiche-gallery-item" );
                $thumbnail = $container.find( ".zonepro-thumbnail" );
                $playlist = $container.find( ".fiche-gallery-thumbnails" );
                $currentItem = $playlist.find( ".fiche-gallery-thumbnail-item a" ).first();
                if( $currentItem.length > 0 ){
                    setThumbnail();
                    initPlaylist();
                    if( $currentItem.data( "type" ) === "vimeo" ){
                        initPlayer();
                    }
                    setControls();
                    $( window ).resize( setControls );
                }
            }

            init( this );

        } );


    };

    $( ".js-fiche-gallery" ).avantiGallery();

} )( jQuery, Vimeo );