( function( $ ){

    var timeout;
    var $input;
    var $searchRequest;
    var previousRequest;

    $( ".search-form-container .searchbutton, #listings-no-results u" ).click( function() {
        $( "body" ).toggleClass( "search-menu-open" );
        if( $( "body" ).hasClass( "search-menu-open" ) ){
            $( ".search-form-container .search-input" ).focus();
        }else{
            $( ".search-form-container .search-input" ).blur();
        }
    } );

    function beforeSend() {
        
    }

    function success( resp ) {
        if( resp.data.length > 0 ){
            $( ".search-results-container .no-results, .search-results-container .loader" ).fadeOut( 300,  function(){
                
                $( ".search-lists" ).html( "" );
                $( ".search-lists-title" ).hide();
                $.each( resp.data, function () {
                    var string = "<div class='col-12 col-md-3'>" +
                        "<div id='" + this.id + "' class='listItem'>" +
                        "<div class='itemBackground js-tilt-full' style='background: url(" + this.image + ")center center no-repeat;background-size: cover;'>" +
                        "<a href='" + this.url + "' class='gradientShadow'></a>" +
                        "<div class='content'>" +
                        "<div class='genre'>" + this.genre + "</div>" +
                        "<a href='" + this.url + "' class='title'>" + this.title + "</a>" +
                        "</div>" +
                        "</div>" +
                        "</div>" +
                        "</div>";
                    $( "#search-list .search-lists-title-"+this.type ).css( "display", "block" );
                    $( "#search-list .search-lists.search-list-section-"+this.type ).append( string );
                });
                $( ".search-results-container #search-list" ).fadeIn( 300 );

            } );
        }else{
            $( ".search-results-container .loader" ).fadeOut( 300, function(){
                $( ".search-results-container .no-results" ).fadeIn( 300 );
            } );
        }

        // $("#search-list .listItem").each(function () {
        //     var found = false;
        //     var id = $(this).attr("id");
        //     $.each(resp.data, function () {
        //         if (id === this.id) {
        //             found = true;
        //         }
        //     });

        //     if (!found) {
        //         $(this).parents(".col-12").fadeOut( 300, function () {
        //             $(this).remove();
        //         });
        //     }
        // });

        // }
    }

    function error( jqXHR, code ) {
        if( jqXHR.aborted ){
            return;
        }else{
            console.log( code );
        }
    }

    function call() {
        $( ".search-results-container #search-list" ).fadeOut( 300, function(){
            $( ".search-results-container .loader" ).fadeIn( 300 );
            if( $searchRequest != null ){
                $searchRequest.abort();
            }
            $searchRequest = $.ajax({
                url: "/wp/wp-admin/admin-ajax.php",
                method: "get",
                data: {
                    action: 'avanti_advance_search',
                    s: $input.val()
                },
                beforeSend: beforeSend,
                success: success,
                error: error
            });
        } );
    }

    $( ".search-form-container .search-input" ).keyup( function(){
        $input = $( this );
        clearTimeout( timeout );
        console.log( previousRequest + " " + $input.val() );
        
        if( $input.val() !== previousRequest || true ){
            if( $input.val().length > 2 ){    
                timeout = setTimeout( call, 500 );
            }else if( $input.val().length === 0 ){
                $( ".search-results-container #search-list, .search-results-container .no-results" ).fadeOut( 300, function(){
                    $( ".search-lists-title" ).hide();
                    $( ".search-lists" ).html( "" );
                } );
            }
        }
        
    } );

} )( jQuery );